import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store/index';

Vue.use(VueRouter);

// # 지우기

const routes = [
	// 메인 페이지
	{
		path: '/',
		component: () => import('@/views/Main.vue'),
	},
	// 로그인 페이지
	{
		path: '/login',
		component: () => import('@/views/Login.vue'),
	},
	/* 회원가입 */
	{
		path: '/signup',
		component: () => import('@/views/Signup.vue'),
		children: [
			// 회원가입
			{
				path: '',
				component: () => import('@/components/Signup/SignupForm.vue'),
			},
			// 회원가입 완료
			{
				path: '/signup/complete',
				component: () => import('@/components/Signup/SignupComplete.vue'),
			},
		],
	},
	// 이용 약관
	{
		path: '/terms',
		component: () => import('@/views/Terms.vue'),
	},
	// 개인정보 보호
	{
		path: '/privacy',
		component: () => import('@/views/Privacy.vue'),
	},
	// 서비스 페이지
	{
		path: '/service',
		component: () => import('@/views/Service.vue'),
	},
	// 서비스 상세 페이지 - 보안
	{
		path: '/servicesecurity',
		component: () => import('@/views/ServiceDetailSecurity.vue'),
	},
	// 서비스 상세 페이지 - 암호
	{
		path: '/servicecrypto',
		component: () => import('@/views/ServiceDetailCrypto.vue'),
	},
	// 서비스 상세 페이지 - 백신
	{
		path: '/servicevaccine',
		component: () => import('@/views/ServiceDetailVaccine.vue'),
	},
	// service BI
	{
		path: '/serviceBiMining',
		component: () => import('@/views/ServiceDetailBIMining.vue'),
	},
	{
		path: '/solutionvaccine',
		component: () => import('@/views/SolutionDetailVaccine.vue'),
	},

	{
		path: '/solutioncrypto',
		component: () => import('@/views/SolutionDetailCrypto.vue'),
	},
	// 솔루션 페이지
	{
		path: '/solution',
		component: () => import('@/views/Solution.vue'),
	},
	// 요금 페이지
	{
		path: '/pricing',
		component: () => import('@/views/Pricing.vue'),
	},
	// 요금 암호 페이지
	{
		path: '/pricingcrypto',
		component: () => import('@/views/PricingCrypto.vue'),
	},
	// 요금 보안 페이지
	{
		path: '/pricingsecurity',
		component: () => import('@/views/PricingSecurity.vue'),
	},
	// 요금 BI 마이닝 페이지
	{
		path: '/pricingbimining',
		component: () => import('@/views/PricingBIMining.vue'),
	},
	// 요금 백신 페이지
	{
		path: '/pricingvaccine',
		component: () => import('@/views/PricingVaccine.vue'),
	},
	// 페이먼츠 팝업 창
	{
		path: '/paymentspopup',
		component: () => import('@/views/PaymentsPopup.vue'),
	},
	// 페이먼츠 마이닝 팝업 창
	{
		path: '/paymentspopup/mining',
		component: () => import('@/views/PaymentsPopupMining.vue'),
	},
	// 페이먼츠 팝업 정기 구독 페이지
	{
		path: '/paymentspopup/paysubscribe',
		component: () => import('@/components/payments/PaymentsSubscribe.vue'),
	},
	// 페이먼츠 팝업 결제 완료 페이지
	{
		path: '/paymentspopup/paysuccess',
		component: () => import('@/components/payments/PaymentsSuccess.vue'),
	},
	// 결제 취소 페이지
	{
		path: '/paycancel',
		component: () => import('@/components/payments/PaymentsCancel.vue'),
	},
	/* 문의하기 페이지 */
	{
		path: '/inquiry',
		component: () => import('@/views/Inquiry.vue'),
		children: [
			{
				path: '',
				component: () => import('@/components/Inquiry/InquiryContent.vue'),
			},
			{
				path: '/inquiry/complete',
				component: () => import('@/components/Inquiry/InquiryComplete.vue'),
			},
		],
	},
	{
		path: '/warning',
		component: () => import('@/views/Warning.vue'),
	},
	/* 마이 페이지 */
	{
		path: '/mypage',
		component: () => import('@/views/Mypage.vue'),
		beforeEnter: (to, from, next) => {
			if (!store.getters.isLogin) {
				const confirmContent = confirm(
					'로그인이 필요한 페이지입니다. 로그인 페이지로 이동하시겠습니까?',
				);
				if (confirmContent) {
					next('/login');
				} else {
					next('/');
				}
				console.log('인증이 필요합니다.');
			} else {
				next();
			}
		},
		children: [
			// 마이 페이지 / 메인
			{
				path: '',
				component: () => import('@/components/Mypage/MypageMain.vue'),
			},
			// 마이 페이지 / 회원정보 변경
			{
				path: '/mypage/changeinfo',
				component: () => import('@/components/Mypage/ChangeInfo.vue'),
			},
			// 마이 페이지 / 회원 탈퇴
			{
				path: '/mypage/withdrawal',
				component: () => import('@/components/Mypage/Withdrawal.vue'),
			},

			{
				path: '/mypage/warning',
				component: () => import('@/components/common/WarningContent.vue'),
			},
		],
	},
	{
		path: '/access',
		component: () => import('@/views/Access.vue'),
	},
	{
		path: '/userguide',
		component: () => import('@/views/Userguide.vue'),
	},
	{
		path: '/userauth',
		component: () => import('@/views/Userauth.vue'),
	},
	{
		path: '/faq',
		component: () => import('@/views/FAQ.vue'),
	},
	// 마이 페이지 / 회원 탈퇴 / 완료
	{
		path: '/mypage/withdrawal/complete',
		component: () => import('@/components/WithdrawalComplete.vue'),
	},

	/* 비밀번호 찾기 */
	{
		path: '/findpw',
		component: () => import('@/views/FindPw.vue'),
		// meta: { authRequired: true },
		children: [
			// 비밀번호 찾기
			{
				path: '/findpw/form',
				component: () => import('@/components/FindPw/FindPw.vue'),
			},
			// 비밀번호 찾기 / 완료
			{
				path: '/findpw/complete',
				component: () => import('@/components/FindPw/FindPwComplete.vue'),
			},
		],
	},
	/* 비밀번호 재설정 */
	{
		path: '/modifypw',
		component: () => import('@/views/ModifyPw.vue'),
		children: [
			// 비밀번호 재설정 / 폼
			{
				path: '/modifypw/form',
				component: () => import('@/components/ModifyPw/ModifyPw.vue'),
			},
			// 비밀번호 재설정 / 완료
			{
				path: '/modifypw/complete',
				component: () => import('@/components/ModifyPw/ModifyPwComplete.vue'),
			},
		],
	},
	/* 404 에러 페이지 */
	{
		path: '*',
		redirect: '/404',
	},
	{
		path: '/404',
		component: () => import('@/views/PageNotFound.vue'),
	},
];

const router = new VueRouter({
	mode: 'history',
	routes,

	// 스크롤 초기화
	scrollBehavior() {
		window.scrollTo(0, 0);
	},
});

export default router;

router.beforeEach(function (to, from, next) {
	// to: 이동할 url에 해당하는 라우팅 객체
	if (
		to.matched.some(function (routeInfo) {
			return routeInfo.meta.authRequired;
		})
	) {
		// 이동할 페이지에 인증 정보가 필요하면 경고 창을 띄우고 페이지 전환은 하지 않음
		alert('로그인이 필요한 페이지입니다.');
		next('/');
	} else {
		console.log("routing success : '" + to.path + "'");
		next(); // 페이지 전환
	}
});
