const animatedScrollObserver = new IntersectionObserver(
	(entries, animatedScrollObserver) => {
		entries.forEach(entry => {
			if (entry.isIntersecting) {
				entry.target.classList.add('enter');
				animatedScrollObserver.unobserve(entry.target);
			}
		});
	},
);

const animatedScrollObserver2 = new IntersectionObserver(
	(entries, animatedScrollObserver2) => {
		entries.forEach(entry => {
			if (entry.isIntersecting) {
				entry.target.classList.add('enter2');
				animatedScrollObserver2.unobserve(entry.target);
			}
		});
	},
);

export default {
	bind(el) {
		el.classList.add('before-enter');
		animatedScrollObserver.observe(el);
	},

	bind2(el) {
		animatedScrollObserver2.observe(el);
		el.classList.add('before-enter2');
	},
};
