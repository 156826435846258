import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import './assets/scss/_index.scss';
import scrollanimation from './utils/scrollanimation';

Vue.directive('scrollanimation', scrollanimation);

Vue.config.productionTip = false;

new Vue({
	router,
	store,
	render: h => h(App),
}).$mount('#app');
