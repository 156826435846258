<template>
	<div>
		<router-view />
	</div>
</template>

<script>
export default {
	components: {},
};
</script>

<style></style>
