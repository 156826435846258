import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

export default new Vuex.Store({
	plugins: [createPersistedState()],
	//back에서 받은 기업 구분값store에 넣어주는 내용임 (변수 수정 가능)
	state: {
		userid: '',
		userCom: 0,
		coupons: [], // Add the coupons state
	},
	getters: {
		// 로그인 시 true / 로그아웃 시 false
		isLogin(state) {
			return state.userid !== '';
		},
	},
	// userid를 받아서 setUserid에 넣어줌
	mutations: {
		setUserid(state, userid) {
			state.userid = userid;
		},
		setUserCom(state, userCom) {
			state.userCom = userCom;
		},
		// 로그아웃
		clearUserid(state) {
			state.userid = '';
			state.userCom = 0;
		},
	},
	actions: {},
});
